
@layer components {
  .snackbar-shadow {
    box-shadow: 0px 3px 8px rgba(var(--neutral-800), 0.1), 0px 2px 1px rgba(var(--neutral-800), 0.05);
  }
  .bottom-bar-shadow {
    box-shadow: 0px -3px 16px rgba(var(--neutral-800), 0.1), 0px 2px 1px rgba(var(--neutral-800), 0.05);
  }
  .sm-shadow {
    box-shadow: 0px 0px 3px rgba(var(--neutral-800), 0.1);
  }
}
